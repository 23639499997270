import {
  add,
  prop,
  pipe,
  multiply,
  pluck,
  reduce,
  mergeDeepLeft,
  subtract,
} from 'ramda';
import sortByYear from '../../../utils/sortByYear';
import formatNumber from '../../../utils/formatNumber';
import calculateSignalValue from '../../../utils/calculateSignalValue';

export const formatCalculatedResistance = pipe(
  prop('calculatedResistance'),
  multiply(100),
  n => n.toFixed(2)
);

export const getMaterialFixedAssets = prop('materialFixedAssets');
export const getEquity = prop('equity');

export const formatPartnerships = ({ edges }) => {
  const nodes = pluck('node', edges);
  return nodes;
};

export const getExcessiveReserve = prop('excessiveReserve');
export const getExcessiveReservePercentage = prop('excessiveReservePercent');
export const getSignalValue = prop('signalValue');
export const getNetGain = prop('netGain');
export const getPublicEquity = prop('publicEquity');

export const formatNationalEquity = (indicator, prognoses, sector) => {
  const data = mergeDeepLeft(indicator, prognoses);

  const setValue = (dataObj, field) => {
    return dataObj && dataObj[field] ? Math.round(dataObj[field]) : null;
  };

  const calculateDifference = (a, b) => {
    const value = subtract(a, b);
    return value === 0 ? null : value;
  };

  const formattedData = reduce(
    (acc, year) => {
      const prognosisDifference = calculateDifference(
        setValue(data[year][sector], 'nationalTotalEquity'),
        setValue(data[year][sector], 'nationalSignalValue')
      );

      const differrence = calculateDifference(
        setValue(data[year][sector], 'publicEquity'),
        setValue(data[year][sector], 'signalValue')
      );

      acc.push({
        label: year.replace('_', ''),
        Eigenvermogen: setValue(data[year][sector], 'publicEquity'),
        Signaleringswaarde: setValue(data[year][sector], 'signalValue'),
        PrognoseEigenvermogen: setValue(
          data[year][sector],
          'nationalTotalEquity'
        ),
        PrognoseSignaleringswaarde: setValue(
          data[year][sector],
          'nationalSignalValue'
        ),
        PrognoseAfwijking: prognosisDifference,
        Afwijking: differrence,
      });
      return acc;
    },
    [],
    sortByYear(Object.keys(data))
  );

  return formattedData;
};

export const calculatePrognosisValues = prognosisData => {
  const sortedBySector = reduce(
    (acc, entry) => {
      if (entry.prognoses.length > 1) {
        entry.sector === 'PO'
          ? acc['po'].push(...entry.prognoses)
          : acc['vo'].push(...entry.prognoses);
      }
      return acc;
    },
    {
      po: [],
      vo: [],
    },
    prognosisData
  );

  const sortByYear = sectorData => {
    return reduce(
      (acc, year) => {
        acc[year] = {
          PO: sectorData['po'].filter(item => `_${item.year}` === year),
          VO: sectorData['vo'].filter(item => `_${item.year}` === year),
        };
        return acc;
      },
      {},
      ['_2017', '_2018', '_2019', '_2020', '_2021', '_2022', '_2023']
    );
  };

  const calculateTotal = yearlyData => {
    const calculate = (data, sector) => {
      return reduce(
        (acc, year) => {
          acc[year] = {};

          const result = yearlyData[year][sector].reduce((acc, entry) => {
            acc = {
              prognosisBenefitsEquity: add(
                acc['prognosisBenefitsEquity'] || 0,
                formatNumber(entry.prognosisBenefitsEquity)
              ),
              prognosisBenefitsGrossIncome: add(
                acc['prognosisBenefitsGrossIncome'] || 0,
                formatNumber(entry.prognosisBenefitsGrossIncome)
              ),
            };
            return acc;
          }, {});

          acc[year][sector] = acc[year][sector]
            ? {
                nationalTotalEquity: add(
                  acc[year][sector]['nationalTotalEquity'],
                  result.prognosisBenefitsEquity
                ),
                nationalSignalValue: calculateSignalValue(
                  add(
                    acc[year][sector]['nationalSignalValue'],
                    result.prognosisBenefitsGrossIncome
                  )
                ),
              }
            : {
                nationalTotalEquity: Number(result.prognosisBenefitsEquity),
                nationalSignalValue: calculateSignalValue(
                  result.prognosisBenefitsGrossIncome
                ),
              };
          return acc;
        },
        {},
        ['_2017', '_2018', '_2019', '_2020', '_2021', '_2022', '_2023']
      );
    };

    return mergeDeepLeft(
      calculate(yearlyData, 'PO'),
      calculate(yearlyData, 'VO')
    );
  };

  return calculateTotal(sortByYear(sortedBySector));
};
