import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import {
  isNil,
  map,
  reduce,
  path,
  subtract,
  equals,
  prop,
  propEq,
} from 'ramda';

import theme from '@greenberry/salal/theme';
import HeroImage from '@greenberry/salal/components/HeroImage';
import Strong from '@greenberry/salal/components/Strong';
import Formula from '@greenberry/salal/components/Charts/Formula';
import InteractiveMap from '@greenberry/salal/components/InteractiveMap';
import formatValue from '@greenberry/salal/utils/formatValue';
import LineChart from '@greenberry/salal/components/Charts/Line';
import PredictionLineChart from '@greenberry/salal/components/Charts/PredictionLine';

import NationalLayout from '../../../components/NationalLayout';
import ThemeFooter from '../../../components/ThemeFooter';
import Loading from '../../../components/Loading';
import ChartWrapper from '../../../components/ChartWrapper';
import { formatNationalReservePosition } from '../../../components/Comparison/ReservePosition/utils';

import useMapJson from '../../../hooks/useMapJson';

import formatDataSource from '../../../utils/formatDataSource';
import formatEqualization from '../../../utils/formatEqualization';
import parseTooltip from '../../../utils/parseTooltip';
import notNil from '../../../utils/notNil';
import isMobile from '../../../utils/isMobile';

import { formatNationalEquity, calculatePrognosisValues } from './utils';

import {
  formatCalculatedResistance,
  getMaterialFixedAssets,
  getEquity,
  formatPartnerships,
  getPublicEquity,
  getNetGain,
  getSignalValue,
  getExcessiveReserve,
} from './utils';
import equalizationColorMap from '../../../constants/equalizationColorMap';

const indicators = [
  {
    name: 'reservePositionNational',
    Chart: LineChart,
    xKey: 'label',
    unit: 'euro',
    width: 600,
    height: 600,
    sideNavData: {
      name: 'Reservepositie',
    },
  },
  {
    name: 'eigen vermogen',
    Chart: LineChart,
    xKey: 'label',
    unit: 'euro',
    width: 600,
    height: 600,
    sideNavData: {
      name: 'Eigen vermogen',
    },
  },

  {
    name: 'reservepositie',
    sideNavData: {
      name: 'Reservepositie',
    },
  },
  {
    name: 'vereveningen',
    sideNavData: {
      name: 'Vereveningen',
    },
  },
];

const Financial = ({ pageContext: { copy, data, sector }, ...props }) => {
  const isPO = equals('primair-onderwijs', sector);
  const reservePosition = path(
    ['reservePositionNational', '_2020', isPO ? 'PO' : 'VO'],
    data
  );
  const signalingValue = path(
    ['signalingValue', '_2020', isPO ? 'PO' : 'VO'],
    data
  );

  const setSelectedView = (indicator, view) => () => {
    const clone = Object.assign({}, selectedViews);
    clone[indicator] = view;
    setSelectedViews(clone);
  };

  const { allPartnership, allPrognosis, ...dataSources } =
    useStaticQuery(graphql`
      query {
        reservePosition: datoCmsDataSource(
          indicator: { eq: "Reservepositie" }
        ) {
          source
          date
        }
        equalizationSource: datoCmsDataSource(
          indicator: { eq: "Vereveningen" }
        ) {
          source
          date
        }
        signalingValue: datoCmsDataSource(
          indicator: { eq: "Signaleringswaarden" }
        ) {
          source
          date
        }

        allPartnership {
          edges {
            node {
              name
              externalId
              slug
              sector
              zipcode
              city
              houseNumber
              houseNumberAddition
              street

              indicators {
                equalization {
                  _2015_2020 {
                    equalizationRate
                    equalizationCategory
                    amountPerStudent
                  }
                }
                signalingValue {
                  _2020 {
                    publicEquity
                    signalValue
                    netGain
                    excessiveReservePercent
                    excessiveReserve
                  }
                }
              }
            }
          }
        }
        allPrognosis: allDatoCmsPartnership {
          nodes {
            sector
            prognoses {
              year
              prognosisBenefitsEquity
              prognosisBenefitsGrossIncome
              prognosisChargesBasicSupport
              prognosisChargesCooperationFormsSpecialRegular
              prognosisChargesCurrentReserveActivity
              prognosisChargesEducationTeachers
              prognosisChargesExpansionArrangements
              prognosisChargesExtraSupportRegularSchools
              prognosisChargesFinancialImpuls
              prognosisChargesGrowthProVso
              prognosisChargesRemainder
              prognosisChargesRegularSchoolExperts
              prognosisChargesQualityAssurance
              prognosisChargesNewModelLwooPro
              prognosisChargesImprovementBusinessOperations
            }
          }
        }
      }
    `);

  const equityData = calculatePrognosisValues(allPrognosis.nodes);

  const sideNavOptions = [
    {
      name: 'Eigen vermogen',
      id: 'eigen-vermogen',
    },
    {
      name: 'Reservepositie',
      id: 'reservepositie-reserveposition',
    },
    {
      name: 'Signaleringswaarden',
      id: 'signaleringswaarden',
    },
    {
      name: 'Vereveningen',
      id: 'vereveningen',
    },
  ];

  const [selectedViews, setSelectedViews] = useState(
    reduce(
      acc => ({
        [indicators[0].name]: 'overview',
        [indicators[1].name]: 'overview',
        [indicators[2].name]: 'overview',
        [indicators[3].name]: 'overview',
        ...acc,
      }),
      {},
      indicators
    )
  );
  const [selectedIndicator, selectIndicator] = useState(sideNavOptions[0].name);
  const mapJson = useMapJson(sector === 'primair-onderwijs');
  const partnerships = formatPartnerships(allPartnership);
  const equalization = formatEqualization(allPartnership, sector);

  const formattedReservePosition = {
    reservePosition: formatNationalReservePosition(
      data['reservePositionNational'],
      isPO ? 'PO' : 'VO'
    ),
  };

  const chartState = {
    setSelectedView,
    selectedViews,
    selectIndicator,
  };

  return (
    <NationalLayout
      title={copy.heroHeading}
      body={copy.heroBody}
      seoMetaTags={copy.seoMetaTags}
      heroImage={<HeroImage src="/images/DPO-financien.png" opacity="1" />}
      sideNavOptions={sideNavOptions}
      selectedIndicator={selectedIndicator}
      {...props}
    >
      {/** Budget */}
      <ChartWrapper
        indicator={indicators[0]}
        sideNavOption={sideNavOptions[0]}
        chartState={chartState}
        heading={parseTooltip(copy.equityTitle)}
        text={parseTooltip(copy.equityBody)}
        chartTitle={'Eigen vermogen'}
        chartSource={formatDataSource(dataSources.signalingValue)}
        chartLegend={[
          { label: 'Eigen vermogen', color: theme.color('chart.1') },
          {
            label: 'Signaleringswaarde',
            color: theme.color('chart.3'),
          },
          {
            label: 'Prognose eigen vermogen',
            color: theme.color('chart.1'),
            stroked: true,
          },
          {
            label: 'Prognose signaleringswaarde',
            color: theme.color('chart.3'),
            stroked: true,
          },
        ]}
        chartLegendType={'circle'}
        chartLegendStyle={'column'}
        explanationTitle="Toelichting"
        explanationText={copy.signalingValueExplanation}
      >
        <PredictionLineChart
          data={formatNationalEquity(
            data['equityNational'],
            equityData,
            isPO ? 'PO' : 'VO'
          ).sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          )}
          height={400}
          xKey="label"
          unit={'EUR'}
        />
      </ChartWrapper>

      {/** Reservepositie */}
      <ChartWrapper
        indicator={indicators[1]}
        sideNavOption={sideNavOptions[1]}
        chartState={chartState}
        heading={parseTooltip(copy.reservePositionHeading)}
        text={parseTooltip(copy.reservePositionBody)}
        chartTitle={'Reservepositie'}
        chartHistoryTitle={'Historische Reservepositie:'}
        chartSource={formatDataSource(dataSources.reservePosition)}
        hasHistoryChart
        historyChildren={
          <LineChart
            showTitle={isMobile()}
            unit="percentage"
            data={map(
              year => ({
                ...reduce(
                  (acc, curr) => {
                    if (notNil(curr)) {
                      acc[curr[indicators[0].xKey]] = curr.values[0].value;
                    }
                    return acc;
                  },
                  {},
                  formattedReservePosition['reservePosition'][year]
                ),
                [indicators[0].xKey]: year,
              }),
              Object.keys(formattedReservePosition['reservePosition']).filter(
                year => {
                  formattedReservePosition['reservePosition'][year].reduce(
                    (acc, curr) => {
                      if (isNil(curr) || acc === false) {
                        return false;
                      }
                      return acc;
                    },
                    true
                  );
                  return data;
                }
              )
            )}
            height={indicators[0].height}
            xKey="label"
            {...indicators[0].chartProps}
          />
        }
        historyChartSource={formatDataSource(dataSources.reservePosition)}
        explanationTitle="Toelichting"
        explanationText={copy.reservePositionExplanation}
      >
        <Formula
          operator="division"
          result={{
            unit: 'percentage',
            value: formatCalculatedResistance(reservePosition),
          }}
          values={[
            {
              description: 'Eigen vermogen – Materiële vaste activa',
              unit: 'EUR',
              tooltip: (
                <>
                  <p>
                    Eigen vermogen:
                    <br />
                    <Strong>
                      {formatValue(getEquity(reservePosition), 'EUR')}
                    </Strong>
                  </p>
                  <p>
                    Materiële vaste activa:
                    <br />
                    <Strong>
                      {formatValue(
                        getMaterialFixedAssets(reservePosition),
                        'EUR'
                      )}
                    </Strong>
                  </p>
                </>
              ),
              value: subtract(
                getEquity(reservePosition),
                getMaterialFixedAssets(reservePosition)
              ),
            },
            {
              description: 'Rijksbijdrage (baten)',
              unit: 'EUR',
              value: prop('governmentGrants', reservePosition),
            },
          ]}
        />
      </ChartWrapper>

      {/** Signaleringswaarde */}
      <ChartWrapper
        indicator={indicators[2]}
        sideNavOption={sideNavOptions[2]}
        chartState={chartState}
        heading={parseTooltip(copy.signalingValueHeading)}
        text={parseTooltip(copy.signalingValueBody)}
        chartTitle={'Signaleringswaarde'}
        chartSource={formatDataSource(dataSources.signalingValue)}
        explanationTitle="Toelichting"
        explanationText={copy.signalingValueExplanation}
      >
        <Formula
          operator="subtraction"
          result={{
            unit: 'EUR',
            value: getExcessiveReserve(signalingValue),
          }}
          values={[
            {
              description: 'Eigen vermogen',
              unit: 'EUR',
              value: getPublicEquity(signalingValue),
            },
            {
              description: 'Signaleringswaarde',
              tooltip: (
                <>
                  <p>
                    <Strong>3,5%</Strong> van{' '}
                    <Strong>
                      {formatValue(getNetGain(signalingValue), 'EUR')}
                    </Strong>
                  </p>
                  <br />
                  <p>
                    3,5% van het totale baten
                    <br /> met een minimum van <br />
                    {formatValue('250000', 'EUR')}.
                  </p>
                </>
              ),
              unit: 'EUR',
              value: getSignalValue(signalingValue),
            },
          ]}
        />
      </ChartWrapper>

      {/** Vereveningen */}
      <ChartWrapper
        indicator={indicators[3]}
        sideNavOption={sideNavOptions[3]}
        chartState={chartState}
        heading={parseTooltip(copy.equalizationHeading)}
        text={parseTooltip(copy.equalizationBody)}
        chartTitle={'Vereveningen'}
        chartSource={formatDataSource(dataSources.equalizationSource)}
        chartLegend={Object.keys(equalizationColorMap).map(label => ({
          label,
          color: equalizationColorMap[label],
        }))}
        chartLegendStyle={'column'}
        explanationTitle="Toelichting"
        explanationText={copy.equalizationExplanation}
      >
        <>
          {mapJson !== null ? (
            <InteractiveMap
              sector={sector}
              active={equalization}
              mapJson={mapJson}
              partnerships={equalization}
              tooltipKeys={['formattedAmountPerStudent']}
              onItemClick={item => {
                const { slug } = partnerships.find(propEq('externalId', item));
                navigate(`/samenwerkingsverband/${sector}/${slug}/financien/`);
              }}
              style={{ height: '470px', zIndex: 5 }}
            />
          ) : (
            <Loading style={{ height: '470px', zIndex: 5 }} />
          )}
        </>
      </ChartWrapper>

      <ThemeFooter
        sector={sector}
        next={{
          label: 'Volgende hoofdstuk: Organisatie',
          to: '/organisatie',
        }}
        prev={{
          label: 'Vorige hoofdstuk: Leerlingen',
          to: '/leerlingen',
        }}
      />
    </NationalLayout>
  );
};

Financial.propTypes = {
  pageContext: PropTypes.shape({
    sector: PropTypes.string.isRequired,
    data: PropTypes.shape({
      reservePosition: PropTypes.shape({
        _2019: PropTypes.shape({
          calculatedResistance: PropTypes.number.isRequired,
          equity: PropTypes.number.isRequired,
          governmentGrant: PropTypes.number.isRequired,
          materialFixedAssets: PropTypes.number.isRequired,
          resistance: PropTypes.number.isRequired,
        }).isRequired,
      }),
      reservePositionNational: PropTypes.shape({
        _2019: PropTypes.object,
      }),
      equityNational: PropTypes.object,
    }).isRequired,
    copy: PropTypes.shape({
      heroHeading: PropTypes.string.isRequired,
      heroBody: PropTypes.string.isRequired,
      supportBudgetHeading: PropTypes.string.isRequired,
      supportBudgetBody: PropTypes.string.isRequired,
      supportBudgetExplanation: PropTypes.string,
      equalizationHeading: PropTypes.string.isRequired,
      equalizationBody: PropTypes.string.isRequired,
      equalizationExplanation: PropTypes.string,
      equityTitle: PropTypes.string.isRequired,
      equityBody: PropTypes.string.isRequired,
      equityExplanation: PropTypes.string,
      reservePositionHeading: PropTypes.string.isRequired,
      reservePositionBody: PropTypes.string.isRequired,
      reservePositionExplanation: PropTypes.string,
      signalingValueHeading: PropTypes.string.isRequired,
      signalingValueBody: PropTypes.string.isRequired,
      signalingValueExplanation: PropTypes.string,

      seoMetaTags: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Financial;
